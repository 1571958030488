import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileLines,
  faHouse,
  faUserGroup,
  // faUserEdit
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/Logo.png";
import { checkUserHasPermission } from "../utilities/userActionPermissions";
const Navbar = () => {
  let navLinks = [
    {
      icon: faHouse,
      name: "Home",
      to: "/dashboard",
      activeNavs: ["/dashboard"],
    },
  ];
  const activePath = window.location.pathname;
  const dispatch = useDispatch();
  const user = useSelector((store) => store.RECRUITER);
  if (checkUserHasPermission("jd_page", user.role)) {
    navLinks.push({
      icon: faFileLines,
      name: "Job Description",
      to: "/jobdescriptions",
      activeNavs: ["/jobdescriptions"],
    });
  }
  if (checkUserHasPermission("myteam_page", user.role)) {
    navLinks.push({
      icon: faFileLines,
      name: "My Team",
      to: "/myteam",
      activeNavs: ["/myteam"],
    });
  }
  if (checkUserHasPermission("panelist_page", user.role)) {
    navLinks.push({
      icon: faUserGroup,
      name: "Panelist",
      to: "/panelist",
      activeNavs: ["/panelist"],
    });
  }
  return (
    <div className="p-0 nav-shadow">
      <nav className="navbar navbar-expand-lg" style={{ background: "white" }}>
        <div className="px-5">
          <Link to="/dashboard">
            <img src={logo} alt="" style={{ width:"110px" }} />
          </Link>
        </div>
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-auto">
              {navLinks.map((i) => {
                return (
                  <li
                    key={i.name}
                    className={`nav-item ${
                      i.activeNavs.includes(activePath)
                        ? "active-custom-nav"
                        : ""
                    }`}
                  >
                    <Link className="nav-link" to={i.to}>
                      <FontAwesomeIcon icon={i.icon} className="px-2" />
                      {i.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <span className="navbar-text d-flex">
              <div className="user_circle">
                <span> {user?.displayName.substring(0, 1)}</span>
              </div>
              <div className="d-grid">
                <span className="user_name">
                  {" "}
                  <Link to="/profile">{user?.displayName}</Link>
                </span>

                <p
                  onClick={() => dispatch({ type: "LOGOUT_USER" })}
                  className="cursor-pointer"
                >
                  Log Out
                </p>
              </div>
            </span>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
