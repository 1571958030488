// import { toast } from 'react-toastify';

const actionTypes = Object.freeze({
  SET_ACTIONS: "SET_ACTIONS",
  UPDATE_ACTIONS: "UPDATE_ACTIONS",
  CLEAR_ACTIONS: "CLEAR_ACTIONS",
});

export default function actionsReducer(
  state = {
    bookingDetails: {
    },
  },
  action
) {
  switch (action.type) {
    case actionTypes.SET_ACTIONS: // LOGIN
      return { ...action.payload };

    case actionTypes.UPDATE_ACTIONS:
      return { ...state, ...action.payload };

    case actionTypes.CLEAR_ACTIONS: // LOGOUT
      // toast.success('Logged out successfully')
      return null;

    default:
      return state;
  }
}

function setActions(payload) {
  // sets actions, replacing existing
  return { type: actionTypes.SET_ACTIONS, payload };
}

function updateActions(payload) {
  // adds {key: value} pair(s) to existing actions object
  return { type: actionTypes.UPDATE_ACTIONS, payload };
}

function clearActions(payload) {
  // removes all properties in actions, returns empty object
  return { type: actionTypes.CLEAR_ACTIONS, payload };
}

export const actionsActions = {
  setActions,
  updateActions,
  clearActions,
};
