import React from "react";

// ErrorMsgs is to be used inside a Toast upon validation
// errorsArray is an array of strings provided by the Yup validate catch
export default function ErrorMsgs({ errorsArray, message = "" }) {
  // const handleClick = () => {
  //   window.open(
  //     `/window/error?errors=${JSON.stringify(errorsArray)}`,
  //     "newwindow",
  //     "width=500,height=500,right=50,top=200"
  //   );
  // };
  return (
    <div>
      {/* <button
        type="button"
        className="btn mb-1"
        style={{ backgroundColor: "white" }}
        onClick={handleClick}
      >
        View errors in new window
      </button> */}
      {message && <p className="font-weight-bold">{message}</p>}
      <p className="font-weight-bold">Please fix the following:</p>
      <ul style={{ listStyleType: "circle" }}>
        {errorsArray
          ?.filter((e) => e !== "")
          .map((errorString, index) => {
            return <li key={index}>{errorString}</li>;
          })}
      </ul>
    </div>
  );
}

