import { call, put, select, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import bookingRepository from "../../api/bookingRepository";
import { actionsActions } from "../reducers/actionsReducer";
import { bookingActions } from "../reducers/bookingReducer";
import moment from "moment";
import { setHistory } from "../../utilities/historyHandler";
import { convertCreateBookingToEditBookingPayload } from "../../utilities/dataConverters";
import ErrorMsgs from "../../components/ErrorMsgs";
import { appLoaderAppLoader } from "../reducers/appLoader";
// import { appLoaderAppLoader } from "../reducers/appLoader";

export const recruiterSagaTypes = Object.freeze({
  ATTEMPT_LOGIN: "ATTEMPT_LOGIN",
  CHECK_PERSISTED_USER: "CHECK_PERSISTED_USER",
  LOGOUT_USER: "LOGOUT_USER",
  SAVE_USER: "SAVE_USER",
  CREATE_MEETING: "CREATE_MEETING",
  VIEW_MEETING: "VIEW_MEETING",
  RESCHEDULE_MEETING: "RESCHEDULE_MEETING",
  UPDATE_MEETING:"UPDATE_MEETING",
  CANCEL_MEETING:"CANCEL_MEETING",
  APPROVE_MEETING:"APPROVE_MEETING",
  REJECT_MEETING:"REJECT_MEETING",
  SUBMIT_FEEDBACK:"SUBMIT_FEEDBACK",
  ADD_COMMENTS:"ADD_COMMENTS",
  ADD_DOCUMENTS:"ADD_DOCUMENTS"

});
function* createMeeting(action) {
  yield put(actionsActions.updateActions({ overlayLoading: true }));
    const booking = yield select((store) => store.BOOKING);
    const user = yield select ((store)=>store.RECRUITER)
    const {meetingInfo,...bookingPayload} = booking;
    // let fields = ["jobDescription"];
    // fields.forEach((i)=>{
    //   delete bookingPayload[i]
    // })
    const appendData = {
      vendorId: user.vendorId,
      vendorName: user.vendorName,
      recruiterName: user.displayName,
      recruiterEmail: user.email,
      ...action.payload,
    };
  // Call Api and if successful validation of credentials, store user information in user reducer and sessionStorage
  try {
    toast.info("Creating new Meeting");

    const response = yield call(() =>bookingRepository.createNewBooking({...bookingPayload,meetingInfo:{"participants": [
      {
        "name": user.displayName,
        "email": user.email,
        "host": true
      }
    ]},...appendData}));
    // yield put(appLoaderAppLoader.updateAppLoader({showCreateBookingModal:true}))
    // Handle errors
    if (response && response?.bookingId) {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      // yield put(appLoaderAppLoader.updateAppLoader({showCreateBookingModal:true,modalData:{isSucces:true}}))
       toast.success("Meeting created Successfully");
        yield put(actionsActions.updateActions({bookingDetails:{response:response,actionType:'created'}}));
        setHistory('/dashboard')
    } else {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      // yield put(appLoaderAppLoader.updateAppLoader({showCreateBookingModal:true,modalData:{isSucces:false}}))
      console.log(response);
      //  toast.error(<ErrorMsgs errorsArray={errorArray} message={message} />);
      toast.error('unable to create booking')
     
    }
  } catch (e) {
    toast.error("An error occurred, please try again.");
  }
}

function* getBookingDetails(action){
  const {bookingId,actionType} = action.payload
  try {
    yield put(actionsActions.updateActions({ overlayLoading: true }));
     const response = yield call(()=>bookingRepository.getBookingById(bookingId));
     if(response?.status!=='error'){
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      let newStartDate = moment(response?.meetingInfo?.startTime).format(
        "DD/MM/YYYY HH:mm"
      );
      let newEndtDate = moment(response?.meetingInfo?.endTime).format(
        "DD/MM/YYYY HH:mm"
      );
      let newResponse = {
        ...response,
        meetingInfo: {
          ...response.meetingInfo,
          startTime: newStartDate,
          endTime: newEndtDate,
        },
      };

      yield put(bookingActions.setBooking(newResponse));
      yield put(actionsActions.updateActions({bookingDetails:{actionType:actionType}}))
     }
    yield put(actionsActions.updateActions({ overlayLoading: false }));
  } catch (error) {
    
  }
 

}
function* RescheduleMeeting(action) {
  yield put(actionsActions.updateActions({ overlayLoading: true }));
   toast.info("Rescheduling Meeting");
  const booking = yield select((store) => store.BOOKING);
  const user = yield select ((store)=>store.RECRUITER)

  const reSchedulePayload = convertCreateBookingToEditBookingPayload(booking)
  try {
    const response = yield call(() =>
      bookingRepository.reScheduleBooking({...reSchedulePayload,meetingInfo:{"participants": [
        {
          "name": user.displayName,
          "email": user.email,
          "host": true
        }
      ]},...action.payload})
    );
    if (response?.bookingId) {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
       toast.success("Rescheduled Meeting Successfully");
      yield put(bookingActions.setBooking(response));
      setHistory('/dashboard')
      yield put(
        actionsActions.updateActions({
          bookingDetails: { actionType: 'created' },
        })
      );
    }else{
      yield put(
        appLoaderAppLoader.updateAppLoader({
          modalData: { isFailed: true,data:response?.message },
        })
      );
    }
    yield put(actionsActions.updateActions({ overlayLoading: false }));
  } catch (error) {
     toast.error("Unable to rescheduled Meeting");

  }
}


function* updateMeeting(action) {
  yield put(actionsActions.updateActions({ overlayLoading: true }));
  toast.info("Updating Meeting");
  const updateBooking = convertCreateBookingToEditBookingPayload(
    action.payload
  );
  console.log(updateBooking);
  try {
    const response = yield call(() =>
      bookingRepository.reScheduleBooking({ ...updateBooking })
    );
    if (response?.status !== "error") {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      toast.success("Updating Meeting Successfully");
      yield put(bookingActions.setBooking(response));
    } else {
      toast.error(
        <ErrorMsgs errorsArray={[response?.message]} message={response?.code} />
      );
    }
    yield put(actionsActions.updateActions({ overlayLoading: false }));
  } catch (error) {
    toast.error("Unable to Update Meeting");
  }
}

function* addComments(action) {
  yield put(actionsActions.updateActions({ overlayLoading: true }));
  toast.info("Updating Meeting");

  const booking = yield select((store) => store.BOOKING);
  const comments = action.payload; // Assuming action.payload is an array of comments data
  const updatedBooking = {
    ...booking,
    ...comments
  };
  const payload = convertCreateBookingToEditBookingPayload(
    updatedBooking
      );
  yield put(bookingActions.setBooking(payload));

  try {
    const response = yield call(() =>
      bookingRepository.updateComments(payload)
    );
    if (response?.status !== "error") {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      toast.success("Comments added successfully");
      yield put(bookingActions.setBooking(response));
    } else {
      toast.error(
        <ErrorMsgs errorsArray={[response?.message]} message={response?.code} />
      );
    }
    yield put(actionsActions.updateActions({ overlayLoading: false }));
  } catch (error) {
    toast.error("Unable to update comments");
  }
}

function* addDocuments(action) {
  yield put(actionsActions.updateActions({ overlayLoading: true }));
  toast.info("Attaching Documents");

  const booking = action.payload;
  const updatedBooking = {
    ...booking
    };
  const payload = convertCreateBookingToEditBookingPayload(
    updatedBooking
      );
  yield put(bookingActions.setBooking(payload));

  try {
    const response = yield call(() =>
      bookingRepository.updateDocuments(payload)
    );
    if (response?.status !== "error") {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      toast.success("Document  attached successfully");
      yield put(bookingActions.setBooking(response));
    } else {
      toast.error(
        <ErrorMsgs errorsArray={[response?.message]} message={response?.code} />
      );
    }
    yield put(actionsActions.updateActions({ overlayLoading: false }));
  } catch (error) {
    toast.error("Unable to attach document");
  }
}

function* submitFeedBack(action) {
  yield put(actionsActions.updateActions({ overlayLoading: true }));
  toast.info("Submiting Feedback");

  const booking = action.payload;
  const updatedBooking = {
    ...booking
    };
  const payload = convertCreateBookingToEditBookingPayload(
    updatedBooking
      );
  yield put(bookingActions.setBooking(payload));

  try {
    const response = yield call(() =>
      bookingRepository.updateDocuments(payload)
    );
    if (response?.status !== "error") {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      toast.success("Feedback updated successfully");
      yield put(bookingActions.setBooking(response));
    } else {
      toast.error(
        <ErrorMsgs errorsArray={[response?.message]} message={response?.code} />
      );
    }
    yield put(actionsActions.updateActions({ overlayLoading: false }));
  } catch (error) {
    toast.error("Unable to attach document");
  }
}

function* cancelMeeting(action) {
  yield put(actionsActions.updateActions({ overlayLoading: true }));
   toast.info("Canceling Meeting");
  //  const { bookingId, actionType } = action.payload;
  const booking = yield select((store) => store.BOOKING);
  const reSchedulePayload = convertCreateBookingToEditBookingPayload(booking);
  try {
    const response = yield call(() =>
      bookingRepository.cancelBooking(reSchedulePayload)
    );
    if (response?.status !== "error") {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
       toast.success("Canceled Meeting successfully");
      setHistory("/dashboard");
      // yield put(bookingActions.setBooking(response));
      yield put(
        actionsActions.updateActions({
          bookingDetails: { actionType: 'created' },
        })
      );
    }else{
      toast.error(
        <ErrorMsgs errorsArray={[response?.message]} message={response?.code} />
      );
      yield put(actionsActions.updateActions({ overlayLoading: true }));

    }
  } catch (error) {
     toast.error("Unable to Cancel the Meeting");
  }
}

function* approveMeeting(action) {
  yield put(actionsActions.updateActions({ overlayLoading: true }));
   toast.info("Canceling Meeting");
  //  const { bookingId, actionType } = action.payload;
  const booking = yield select((store) => store.BOOKING);
  const reSchedulePayload = convertCreateBookingToEditBookingPayload(booking);
  try {
    const response = yield call(() =>
      bookingRepository.approveBooking(reSchedulePayload)
    );
    if (response?.status !== "error") {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
       toast.success("Approved Meeting successfully");
        setHistory("/dashboard");
      // yield put(bookingActions.setBooking(response));
      yield put(
        actionsActions.updateActions({
          bookingDetails: { actionType: 'approved' },
        })
      );
    }else{
      toast.error(
        <ErrorMsgs errorsArray={[response?.message]} message={response?.code} />
      );
      yield put(actionsActions.updateActions({ overlayLoading: true }));
    }
  } catch (error) {
     toast.error("Unable to Cancel the Meeting");
  }
}

function* rejectMeeting(action) {
  yield put(actionsActions.updateActions({ overlayLoading: true }));
   toast.info("Rejecting Meeting");
  //  const { bookingId, actionType } = action.payload;
  const booking = yield select((store) => store.BOOKING);
  const reSchedulePayload = convertCreateBookingToEditBookingPayload(booking);
  try {
    const response = yield call(() =>
      bookingRepository.rejectBooking(reSchedulePayload)
    );
    if (response?.status !== "error") {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
       toast.success("Rejected Meeting successfully");
        setHistory("/dashboard");
      // yield put(bookingActions.setBooking(response));
      yield put(
        actionsActions.updateActions({
          bookingDetails: { actionType: 'rejected' },
        })
      );
    }else{
      toast.error(
        <ErrorMsgs errorsArray={[response?.message]} message={response?.code} />

      );
      yield put(actionsActions.updateActions({ overlayLoading: true }));


    }
  } catch (error) {
     toast.error("Unable to Reject the Meeting");
  }
}

function* bookingsSaga() {
  yield takeLatest(recruiterSagaTypes.CREATE_MEETING, createMeeting);
  yield takeLatest(recruiterSagaTypes.VIEW_MEETING,getBookingDetails);
  yield takeLatest(recruiterSagaTypes.RESCHEDULE_MEETING,RescheduleMeeting);
  yield takeLatest(recruiterSagaTypes.UPDATE_MEETING,updateMeeting);
  yield takeLatest(recruiterSagaTypes.CANCEL_MEETING,cancelMeeting);
  yield takeLatest(recruiterSagaTypes.APPROVE_MEETING,approveMeeting);
  yield takeLatest(recruiterSagaTypes.REJECT_MEETING,rejectMeeting);
  yield takeLatest(recruiterSagaTypes.ADD_COMMENTS,addComments)
  yield takeLatest(recruiterSagaTypes.ADD_DOCUMENTS,addDocuments)
  yield takeLatest(recruiterSagaTypes.SUBMIT_FEEDBACK,submitFeedBack)

  
}

export default bookingsSaga;
