import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";

import Spinner from "./Spinner";


export default function BackDropLoader() {
  const overlayLoading = useSelector(
    (store) => store?.ACTIONS?.overlayLoading || false
  );
  const [activeElement, setActiveElement] = useState(null);

  useEffect(() => {
    if (overlayLoading) {
      if (document.activeElement && document.activeElement !== document.body) {
        setActiveElement(document.activeElement);
        document.activeElement.blur();
      } else {
        setActiveElement(null);
      }
    } else {
      if (activeElement) {
        activeElement.focus();
      }
    }
  }, [overlayLoading, activeElement, setActiveElement]);

  return overlayLoading
    ? createPortal(
        <div
          className="position-fixed w-100 h-100 top-0 left-0"
          style={{ zIndex: 9999 }}
        >
          <div className="position-absolute w-100 h-100 bg-secondary opacity-50" />
          <Spinner />
        </div>,
        document.body
      )
    : null;
}

