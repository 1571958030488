import React from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import appRoutes from "./appRoutes";
import Navbar from "./components/Navbar";
import { initHistory } from "./utilities/historyHandler";

const MainLayout = (children) => {
    const history = useNavigate();
    initHistory(history);
  return (
    <div className="container-fluid">
      <div className="row position-sticky top-0" style={{ zIndex: 999 }}>
        <Navbar />
      </div>
      <div className="">
        <Routes>
       
        <Route path="/login" element={<Navigate to="/dashboard" />} />
          {appRoutes?.map((eachRoute) => {
            return (
              <Route
                key={eachRoute.path}
                path={eachRoute.path}
                element={<eachRoute.component />}
              />
            );
          })}
            <Route path="/" element={<Navigate to="/dashboard" />} />
           
        </Routes>
      </div>
    </div>
  );
};

export default MainLayout;
