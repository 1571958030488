import "./App.css";
import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PageLoading from "./components/PageLoading";
import Login from "./pages/login/Login";
import { Registration } from "./pages/signup/Registration";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import MainLayout from "./MainLayout";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackDropLoader from "./components/BackDropLoader";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css";
// import { initHistory } from "./utilities/historyHandler";

function App() {
  // will update with actual user logged context
  const isLoggedin = useSelector((store) => store?.RECRUITER?.vendorId); ;
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: "CHECK_PERSISTED_USER" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <div className="">
      {/* <ToastContainer hideProgressBar={true} autoClose={3000}/> */}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <BrowserRouter>
        <Suspense fallback={<PageLoading />}>
          {!isLoggedin ? (
            <Routes>
              <Route path="/*" element={<Navigate to="/login" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/resetpassword" element={<ResetPassword />} />
              <Route path="/signup" element={<Registration />} />
            </Routes>
          ) : (
            <MainLayout />
          )}
        </Suspense>
      </BrowserRouter>
      <BackDropLoader />
    </div>
  );
}

export default App;
