import React, { useEffect, useState } from "react";
// import Form from 'react-bootstrap/Form';
import { Form, InputGroup } from 'react-bootstrap';
// import InputGroup from 'react-bootstrap/InputGroup';
import loginimg from "../../assets/login.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardUser, faEnvelope, faLocationPin, faUserPen, faUserTie, faUsersCog, faUserCheck, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { initHistory } from "../../utilities/historyHandler";
import { useDispatch, useSelector } from "react-redux";
import CommonCard from "../../components/CommonCard";



export const Registration = () => {

  const [registrationObj,setRegistrationObj] = useState({})
  // const [validated, setValidated] = useState(false);  
  const dispatch = useDispatch()
  const customMessage = useSelector(store=>store.APPLOADER)
  const registrationFailed = customMessage?.modalData?.isSucces;


  const navigate = useNavigate();
  initHistory(navigate);
  // const [registrationFailed, setRegistrationFailed] = useState(false);

  const handleContinueToLogin = () => {
    navigate("/login");
  }
  const onCloseHandler = () => {
    dispatch({type:'UPDATE_APP_LOADER',payload:{'showRegistrationModal':false,modalData:{}}})
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    handleRegistration(event);
  };

  // const handleRegistration = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }
  //   else{
  //     if (validateRegistration()){
  //     console.log("no errors");
  //     dispatch({ type: "RECRUITER_REGISTRATION", payload: registrationObj });
  //     //  navigate('/login');
  //     }else{
  //       console.log("errors found");
  //     }
  //   }

  //   // setValidated(true);
  // };


  const handleRegistration = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      try {
        if (validateRegistration()) {
          console.log("no errors");
          dispatch({ type: "RECRUITER_REGISTRATION", payload: registrationObj });
        } else {
          console.log("errors found");
        }
      } catch (error) {
        console.error("Registration failed", error);
      }
    }
  };

  const onHandleChange = (e, value, country) => {
    if (e) {
      // Regular form input
      const key = e.target.name;
      const value = e.target.value;
      setRegistrationObj((prev) => {
        return { ...prev, [key]: value };
      });
    } else {
      // PhoneInput component
      setRegistrationObj((prev) => ({ ...prev, phone: value }));
    }
  };
  

  const [errorsData, setErrorsData] = useState({});
  const validateRegistration = () => {
    let errors = {};
    const validateFields = [
      { key: "firstName", label: "First Name" },
      { key: "lastName", label: "Last Name" },
      { key: "displayName", label: "Display Name" },
      { key: "organization", label: "Organization Name" },
      { key: "email", label: "Email" },
      { key: "designation", label: "Designation" },
      { key: "location", label: "Location" },
      { key: "phone", label: "Mobile number" },
    ];

    for (let field of validateFields) {
      if (!registrationObj?.[field.key]) {
        errors[field.key] = `${field.label} is required`;
      } else if (field.key === "email" && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(registrationObj?.[field.key])) {
        errors[field.key] = "Invalid email address";
      } else if (field.key === "phone" && !/^(?:[0-9] ?){6,14}[0-9]$/.test(registrationObj?.[field.key])) {
        errors[field.key] = "Invalid phone number";
      } else if (field.key === "organization" && registrationObj?.[field.key].length < 4) {
        errors[field.key] = "Organization name must be at least 4 characters long";
      }
    }
    setErrorsData(errors);
    return Object.keys(errors).length === 0;
  };




  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [countdown]);

  console.log(registrationObj);
  return (
    <div>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-6 p-0">
            <div className="container d-flex justify-content-center align-items-center vh-100">
              <div className="p-4">
                <h2 className="text-center mb-4 fw-700">SIGN UP</h2>
                <p>Start & Track your Scheduling Process </p>
                <Form
                  className="form-input-custom"
                  noValidate
                  // validated={validated}
                  onSubmit={handleSubmit}
                >
                  <div className="mb-3">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="firstName">
                        <FontAwesomeIcon icon={faUserTie} />
                      </InputGroup.Text>
                      <Form.Control
                        // required
                        placeholder="First Name"
                        name="firstName"
                        aria-label="firstName"
                        aria-describedby="firstName"
                        onChange={onHandleChange}
                        value={registrationObj?.firstName || ""}
                        // isInvalid={validated && errorsData.firstName}
                      />
                    </InputGroup>
                    <div className="text-danger" style={{fontSize:'12px'}}>{errorsData.firstName}</div>
                  </div>
                  <div className="mb-3">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="lastName">
                        <FontAwesomeIcon icon={faUserTie} />
                      </InputGroup.Text>
                      <Form.Control
                        // required
                        name="lastName"
                        placeholder="Last Name"
                        aria-label="lastName"
                        aria-describedby="lastName"
                        onChange={onHandleChange}
                        value={registrationObj?.lastName || ""}
                        // isInvalid={validated && errorsData.lastName}
                      />
                    </InputGroup>
                    <div className="text-danger" style={{fontSize:'12px'}}>{errorsData.lastName}</div>
                  </div>
                  <div className="mb-3">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="displayName">
                        <FontAwesomeIcon icon={faChalkboardUser} />
                      </InputGroup.Text>
                      <Form.Control
                        // required
                        name="displayName"
                        placeholder="Display Name"
                        aria-label="displayName"
                        aria-describedby="displayName"
                        onChange={onHandleChange}
                        value={registrationObj?.displayName || ""}
                        // isInvalid={validated && errorsData.displayName}
                      />
                    </InputGroup>
                    <div className="text-danger" style={{fontSize:'12px'}}>{errorsData.displayName}</div>
                  </div>
                  <div className="mb-3">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="organization">
                        <FontAwesomeIcon icon={faUsersCog} />
                      </InputGroup.Text>
                      <Form.Control
                        // required
                        name="organization"
                        placeholder="Acme Pvt Ltd"
                        aria-label="organization"
                        aria-describedby="organization"
                        onChange={onHandleChange}
                        value={registrationObj?.organization || ""}
                        // isInvalid={validated && errorsData.organization}
                      />
                    </InputGroup>
                    <div className="text-danger" style={{fontSize:'12px'}}>{errorsData.organization}</div>
                  </div>
                  <div className="mb-3">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="designation">
                        <FontAwesomeIcon icon={faUserPen} />
                      </InputGroup.Text>
                      <Form.Control
                        // required
                        name="designation"
                        placeholder="Senior Recuriter Manager"
                        aria-label="designation"
                        aria-describedby="designation"
                        onChange={onHandleChange}
                        value={registrationObj?.designation || ""}
                        // isInvalid={validated && errorsData.designation}
                      />
                    </InputGroup>
                    <div className="text-danger" style={{fontSize:'12px'}}>{errorsData.designation}</div>
                  </div>
                  <div className="mb-3">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="location">
                        <FontAwesomeIcon icon={faLocationPin} />
                      </InputGroup.Text>
                      <Form.Control
                        // required
                        placeholder="Location"
                        name="location"
                        aria-label="location"
                        aria-describedby="location"
                        onChange={onHandleChange}
                        value={registrationObj?.location || ""}
                        // isInvalid={validated && errorsData.location}
                      />
                    </InputGroup>
                    <div className="text-danger" style={{fontSize:'12px'}}>{errorsData.location}</div>
                  </div>
                  <div className="mb-3">
                    {/* <label htmlFor="email" className="form-label">Email</label>
                  <input type="email" className="form-control" id="email" /> */}
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="email">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        // required
                        placeholder="Enter Email Address"
                        aria-label="email"
                        name="email"
                        aria-describedby="email"
                        onChange={onHandleChange}
                        value={registrationObj?.email || ""}
                        // isInvalid={validated && errorsData.email}
                      />
                    </InputGroup>
                    <div className="text-danger" style={{fontSize:'12px'}}>{errorsData.email}</div>
                  </div>
                  <div className="mb-3">
                    {/* <label htmlFor="phone" className="form-label">Phone Number</label>
                  <input type="tel" className="form-control" id="phone" /> */}
                    <InputGroup className="mb-3">
                      {/* <InputGroup.Text id="phone">
                        <FontAwesomeIcon icon={faMobileScreenButton} />
                      </InputGroup.Text> */}
                      <PhoneInput 
                        country={"in"}
                        enableSearch={true}
                        countryCodeEditable={false}
                        disableSearchIcon={true}
                        value={registrationObj?.phone || ""}
                        onChange={(value, country) => onHandleChange(null, value, country)} 
                      />
                    </InputGroup>
                    <div className="text-danger" style={{fontSize:'12px'}}>{errorsData.phone}</div>
                  </div>
                  <div className="text-center">
                      <button
                        // type="submit"
                        className="btn btn-primary btn-block"
                        // onClick={handleSubmit}
                      >
                        Sign Up
                      </button>
                  </div>
                  <br />
                  <div className="text-center">
                    <p>Already User? <span><a className="primary-color" href="/login">Login</a></span></p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 login_background">
            <div className="row container d-flex justify-content-center align-items-center vh-100">
              <div className="col-md-6">
                <img src={loginimg} alt="" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {customMessage?.showRegistrationModal && (
        <CommonCard 
          title={registrationFailed ? "Registration Failed" : "Verification Email Sent"}
          body={registrationFailed ? "Sorry, something went wrong. Please try again after sometime" : "This action requires email verification. Please check your inbox and follow the instructions. Email sent to John@abc.com"}
          icon={registrationFailed ? faWarning : faUserCheck}
          color={registrationFailed ? "red" : "green"}
          actionButton={!registrationFailed &&"Continue to Login"}
          onActionHandler={!registrationFailed && handleContinueToLogin}
          closeButton="Close"
          onCloseHandler={onCloseHandler}
        />
      )}
    </div>
  );
};
