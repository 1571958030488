// import { toast } from 'react-toastify';

const actionTypes = Object.freeze({
    SET_APP_LOADER: "SET_APP_LOADER",
    UPDATE_APP_LOADER: "UPDATE_APP_LOADER",
    CLEAR_APP_LOADER: "CLEAR_APP_LOADER",
  });
  
  export default function appLoaderReducer(
    state = {
        showLoginModal:false,
        showRestPasswordModal:false,
        showCreateBookigModal:false ,
        showJobDescriptionModal:false, 
        showEditBookingModal:false,
        showPanelistModal:false,
        showRegistrationModal:false,
        modalData:{
          isSucces:false
        }
    },
    action
  ) {
    switch (action.type) {
      case actionTypes.SET_APP_LOADER: 
        return { ...action.payload };
  
      case actionTypes.UPDATE_APP_LOADER:
        return { ...state, ...action.payload };  
      case actionTypes.CLEAR_APP_LOADER: 
        return null;
      default:
        return state;
    }
  }
  
  function setAppLoader(payload) {
    return { type: actionTypes.SET_APP_LOADER, payload };
  }
  
  function updateAppLoader(payload) {
    return { type: actionTypes.UPDATE_APP_LOADER, payload };
  }
  
  function clearAppLoader(payload) {
    return { type: actionTypes.CLEAR_APP_LOADER, payload };
  }
  
  export const appLoaderAppLoader = {
    setAppLoader,
    updateAppLoader,
    clearAppLoader,
  };
  