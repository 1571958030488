const userPermissions = {
  ADMIN: [
    "create_meeting",
    "edit_meeting",
    "reschedule_meeting",
    "create_jd",
    "add_member",
    "add_panelist",
    "cancel_meeting",
    "jd_page",
    "myteam_page",
    "panelist_page"
  ],
  USER_PANEL: [
    "select_candidate",
    "reject_candidate",
    "submit_feedback_candidate",
  ],
  USER_RECRUITER: [
  "cancel_meeting",   
  "create_meeting",
  "edit_meeting",
  "reschedule_meeting",
  "jd_page",
  "myteam_page",
  "panelist_page"
],
};

export const checkUserHasPermission = (code, user) => {
  if (user) {
    return userPermissions[user]?.includes(code);
  }
};
