import { call, put, select, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import recruiterRepository from "../../api/recruiterRepository";
import { setHistory } from "../../utilities/historyHandler";
import { recruiterActions } from "../reducers/recruiterReducer";
import {actionsActions} from '../reducers/actionsReducer'
import {appLoaderAppLoader} from '../reducers/appLoader'
import ErrorMsgs from "../../components/ErrorMsgs";

export const recruiterSagaTypes = Object.freeze({
  ATTEMPT_LOGIN: "ATTEMPT_LOGIN",
  CHECK_PERSISTED_USER: "CHECK_PERSISTED_USER",
  LOGOUT_USER: "LOGOUT_USER",
  SAVE_USER: "SAVE_USER",
  RECRUITER_REGISTRATION: "RECRUITER_REGISTRATION",
  UPDATE_RECRUITER: "UPDATE_RECRUITER",
  GET_RECRUITER_BY_ID:'GET_RECRUITER_BY_ID'
});

function* attemptLogin(action) {
  yield put(actionsActions.updateActions({ overlayLoading: true }));
  // Call Api and if successful validation of credentials, store user information in user reducer and sessionStorage
  try {
    toast.info("Validating User.....");

    const response = yield call(() =>
      recruiterRepository.validateUser(action.payload)
    );
    if (response?.vendorId) {
       toast.success("Validatied User Successfully");
      sessionStorage.setItem("user", JSON.stringify(response));
       yield put(actionsActions.updateActions({ overlayLoading: false }));
      yield put(
        recruiterActions.setUser(response)
      );
      setHistory("/dashboard");
    }else{
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      yield put(recruiterActions.clearUser());
      yield put(appLoaderAppLoader.setAppLoader({ showLoginModal: true }));
      return toast.error(
        <ErrorMsgs errorsArray={[response?.message]} message={response?.code} />
      );
    }
  } catch (e) {
     toast.error("UnExpected Happened Please try later");
     yield put(actionsActions.updateActions({ overlayLoading: false }));
  }
}

function* checkPersistedUser(action) {
  //Look in session storage for previously authenticated user object
  const prevUser = yield window.sessionStorage.user;
  const parsedPrevUser = prevUser ? JSON.parse(prevUser) : null;
  if (parsedPrevUser) {
    // Put user object in Redux store if available
    yield put(recruiterActions.setUser(parsedPrevUser));
  }
}

function* logoutUser(action) {
  toast.warn("Logged out");
  const sessionStorage = window.sessionStorage;
  sessionStorage.clear();
    yield put(recruiterActions.clearUser())
}

function* recruiterRegistration(action) {
  yield put(actionsActions.updateActions({ overlayLoading: true }));
  
  // Call Api and if successful validation of credentials, store user information in user reducer and sessionStorage
  
  try {
    toast.info("creating user");
    yield put(appLoaderAppLoader.updateAppLoader({showRegistrationModal:true}))
    const response = yield call(() =>
      recruiterRepository.recruiterRegistration(action.payload)
    );
    // Handle errors
    if (response?.status === "error") {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      yield put(appLoaderAppLoader.updateAppLoader({showRegistrationModal:true,modalData:{isSucces:false}}))
      return toast.error("unable to create user. Please try again.");
    } else {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      yield put(appLoaderAppLoader.updateAppLoader({showRegistrationModal:true,modalData:{isSucces:true}}))
      toast.success("User created Successfully");
      setHistory("/login");
    }
  } catch (e) {
    toast.error("An error occurred, please try again.");
  }
}


function* updateRecruiter(action) {
  const user = yield select((store) => store.RECRUITER);
  yield put(actionsActions.updateActions({ overlayLoading: true }));
  
  // Call Api and if successful validation of credentials, store user information in user reducer and sessionStorage
  
  try {
    toast.info("updating user");
    yield put(appLoaderAppLoader.updateAppLoader({showRegistrationModal:true}))
    const response = yield call(() =>
      recruiterRepository.updateRecruiter({...user})
    );
    // Handle errors
    if (response?.status === "error") {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      yield put(appLoaderAppLoader.updateAppLoader({showRegistrationModal:true,modalData:{isSucces:false}}))
      return toast.error("unable to update user. Please try again.");
    } else {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      yield put(appLoaderAppLoader.updateAppLoader({showRegistrationModal:true,modalData:{isSucces:true}}))
      toast.success("User updated Successfully");
      // setHistory("/login");
    }
  } catch (e) {
    toast.error("An error occurred, please try again.");
  }
}

function* getRecuriterById(action) {
  try {
    const response = yield call(() =>
      recruiterRepository.getRecuriterById(action.payload.userId)
    );
    if (response?.userId) {
      yield put(recruiterActions.setUser(response));
    }
  } catch (error) {
    toast.error("An error occurred, please try again.");
  }
}

function* recruiterSaga() {
  yield takeLatest(recruiterSagaTypes.ATTEMPT_LOGIN, attemptLogin);
  yield takeLatest(recruiterSagaTypes.CHECK_PERSISTED_USER, checkPersistedUser);
  yield takeLatest(recruiterSagaTypes.LOGOUT_USER, logoutUser);
  yield takeLatest(
    recruiterSagaTypes.RECRUITER_REGISTRATION,
    recruiterRegistration
  );
  yield takeLatest(
    recruiterSagaTypes.UPDATE_RECRUITER,
    updateRecruiter
  );
  yield takeLatest(recruiterSagaTypes.GET_RECRUITER_BY_ID,getRecuriterById)
}

export default recruiterSaga;
