import Fetch from "./Fetch";

async function validateUser(loginObj) {
  // loginObj = { email:'string', password:'string' }
  const response = await Fetch.post("/authenticate", loginObj);
  return response;
}

async function recruiterRegistration(registrationObj) {
//   resetObj = {email: 'string', verificationCode: 'string', newPassword: 'string}
  const response = await Fetch.post("/recruiter/registration", registrationObj);
  return response;
}

async function updateRecruiter(recruiterObj) {
    const response = await Fetch.put("/recruiter/service", recruiterObj);
    return response;
  }
async function getRecuriterById(userId) {
  const response = await Fetch.get(`/recruiter/service?userId=${userId}`);
  return response;
}

async function getAllRecuriters(userId) {
  const response = await Fetch.get(`/recruiter/services/all?vendor=${userId}`);
  return response;
}


const recruiterRepository = {
  validateUser,
  recruiterRegistration,
  getRecuriterById,
  getAllRecuriters,
  updateRecruiter,
};

export default recruiterRepository;
