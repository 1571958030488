import { combineReducers } from "redux";
import recruiterReducer from "./recruiterReducer";
import bookingReducer from "./bookingReducer";
import jobsReducer from "./jobsReducer";
import panelistReducer from "./panelistReducer";
import actionsReducer from "./actionsReducer";
import appLoaderReducer from "./appLoader";

const rootReducer = combineReducers({
  RECRUITER: recruiterReducer,
  BOOKING: bookingReducer,
  JOBS_DETAILS: jobsReducer,
  PANELIST: panelistReducer,
  ACTIONS: actionsReducer,
  APPLOADER:appLoaderReducer
});

export default rootReducer;
