import React from "react";
const ErrorWindow  = React.lazy(()=>import("./components/ErrorWindow"))
const Login = React.lazy(() => import("./pages/login/Login"));
const Registration = React.lazy(() =>import("./pages/signup/Registration"));
const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard"));
const CreateMeeting = React.lazy(() =>import("./pages/newMeeting/CreateMeeting"));
const ViewMeeting = React.lazy(() =>import("./pages/viewMeeting/ViewMeeting"));
const RescheduleMeeting = React.lazy(() =>import("./pages/rescheduleMeeting/RescheduleMeeting"));
const JobDescription = React.lazy(() =>import("./pages/jobDescriptions/JobDescription"));
const MyTeam = React.lazy(()=>import("./pages/myteam/MyTeam"));
const PaneList = React.lazy(() => import("./pages/panelist/PaneList"));
const Profile = React.lazy(()=> import("./pages/userProfile/Profile"));
const ResetPassword = React.lazy(()=>import("./pages/resetPassword/ResetPassword"))
const appRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "Registration",
    component: Registration,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/newmeeting",
    name: "Schedule Meeting",
    component: CreateMeeting,
  },
  {
    path: "/editmeeting/:bookingId",
    name: "Edit Scheduled Meeting",
    component: CreateMeeting,
  },
  {
    path: "/viewmeeting/:bookingId",
    name: "View Scheduled Meeting",
    component: ViewMeeting,
  },
  {
    path: "/reschedulemeeting",
    name: "Re-Scheduled Meeting",
    component: RescheduleMeeting,
  },
  {
    path: "/jobdescriptions",
    name: "Job Descriptions",
    component: JobDescription,
  },
  {
    path: "/panelist",
    name: "Panelist",
    component: PaneList,
  },
  {
    path: "/meeting/:id",
    name: "Meeting",
    component: RescheduleMeeting,
  },
  {
    path: "/myteam",
    name: "My Team",
    component: MyTeam,
  },
  {
    path: "/window/error",
    name: "Errors",
    component: ErrorWindow,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/resetpassword",
    name: "Reset Password",
    component: ResetPassword
  }
];
export default appRoutes;
