import Fetch from "./Fetch";

async function getAllPanelists(vendorName) {
  const response = await Fetch.get(`/panel/services/all?vendor=${vendorName}`);
  return response;
}

async function createPanelist(panelistObj) {
  const response = await Fetch.post("/panel/services", panelistObj);
  return response;
}
async function updatePanelist(panelistObj) {
  const response = await Fetch.put("/panel/services", panelistObj);
  return response;
}
async function addTeamMember(teamMemberObj) {
  const response = await Fetch.post("/recruiter/service", teamMemberObj);
  return response;
}

const panelistRepository = {
  getAllPanelists,
  createPanelist,
  addTeamMember,
  updatePanelist,
};

export default panelistRepository;
