import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import loginimg from "../../assets/login.png"
import { useDispatch } from "react-redux";
import { initHistory } from "../../utilities/historyHandler";
const Login = () => {
  const history = useNavigate();
  initHistory(history);
  const dispatch = useDispatch();
  const [loginObj, setLoginObj] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin(event);
  };

  const handleLogin = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      try {
        if (validateLogin()) {
          console.log("no errors");
          dispatch({ type: "ATTEMPT_LOGIN", payload: loginObj });
        } else {
          console.log("errors found");
        }
      } catch (error) {
        console.error("Login failed", error);
      }
    }
  };

  const [errorsData, setErrorsData] = useState({});
  const validateLogin = () => {
    let errors = {};
    const validateFields = [
      { key: "username", label: "Mail Address" },
      { key: "password", label: "Password" },
    ];

    for (let field of validateFields) {
      if (!loginObj?.[field.key]) {
        errors[field.key] = `${field.label} is required`;
      } else if (
        field.key === "username" &&
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(loginObj?.[field.key])
      ) {
        errors[field.key] = "Invalid Email Address";
      }
    }

    setErrorsData(errors);
    return Object.keys(errors).length === 0;
  };
  
  return (
    <div>
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-md-6 p-0">
            <div className="container d-flex justify-content-center align-items-center vh-100">
              <div className="p-4">
                <h2 className="text-center mb-4 fw-700">LOGIN</h2>
                <p>Start & Track your Scheduling Process </p>
                <div className="warning_box mb-3" style={{ display: Object.keys(errorsData).length > 0 ? "block" : "none" }}>
                  <div className="warning_text p-3">
                    {Object.values(errorsData).map((message, index) => (
                      <p key={index} className="m-0 text-danger">
                        {message}
                      </p>
                    ))}
                  </div>
                </div>
                  {/* <div className="warning_box mb-3">
                    <div className="warning_text p-3">
                      <p className="m-0 text-danger">Invalid Login Credentials</p>
                      <p className="m-0 text-danger">Please Check Your Mail Address and Password</p>
                    </div>
                  </div> */}
                <form className="form-input-custom">
                  <div className="mb-3">
                    {/* <label htmlFor="email" className="form-label">Email</label>
                  <input type="email" className="form-control" id="email" /> */}
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Enter Email Address"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={loginObj?.username}
                        onChange={(e) =>
                          setLoginObj((prev) => {
                            return { ...prev, username: e.target.value };
                          })
                        }
                      />
                    </InputGroup>
                    {/* <div className="text-danger" style={{fontSize:'12px'}}>{errorsData.username}</div> */}
                  </div>
                  <div className="mb-3">
                    {/* <label htmlFor="phone" className="form-label">Phone Number</label>
                  <input type="tel" className="form-control" id="phone" /> */}
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faLock} />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Enter Password"
                        type="password"
                        aria-label="password"
                        aria-describedby="basic-addon1"
                        value={loginObj?.password}
                        onChange={(e) =>
                          setLoginObj((prev) => {
                            return { ...prev, password: e.target.value };
                          })
                        }
                      />
                    </InputGroup>
                    {/* <div className="text-danger" style={{fontSize:'12px'}}>{errorsData.password}</div> */}
                  </div>
                  <div className="text-center d-flex align-items-center flex-column gap-3">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      onClick={handleSubmit}
                    >
                      Login Now
                    </button>
                    <a href="/resetpassword">Forgot Password?</a>
                    <p>Create Account? <span><a className="primary-color" href="/signup">Sign Up</a></span></p>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 login_background">
            <div className="row container d-flex justify-content-center align-items-center vh-100">
              <div className="col-md-6">
                <img src={loginimg} alt="" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
