// import { toast } from 'react-toastify';

const actionTypes = Object.freeze({
  SET_JOBS_DETAILS: "SET_JOBS_DETAILS",
  UPDATE_JOBS_DETAILS: "UPDATE_JOBS_DETAILS",
  CLEAR_JOBS_DETAILS: "CLEAR_JOBS_DETAILS",
});

export default function jobsReducer(
  state = [
  ],
  action
) {
  switch (action.type) {
    case actionTypes.SET_JOBS_DETAILS:
      return [...action.payload];
    case actionTypes.UPDATE_JOBS_DETAILS:
      return [...state, ...action.payload];

    case actionTypes.CLEAR_JOBS_DETAILS:
      return [];

    default:
      return state;
  }
}

function setJobsDetails(payload) {
  // sets jobs, replacing existing
  return { type: actionTypes.SET_JOBS_DETAILS, payload };
}

function updateJobsDetails(payload) {
  // adds {key: value} pair(s) to existing jobs object
  return { type: actionTypes.UPDATE_JOBS_DETAILS, payload };
}

function clearJobsDetails(payload) {
  // removes all properties in jobs, returns empty object
  return { type: actionTypes.CLEAR_JOBS_DETAILS, payload };
}

export const jobsActions = {
  setJobsDetails,
  updateJobsDetails,
  clearJobsDetails,
};
