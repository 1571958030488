import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEnvelopeCircleCheck } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import loginimg from "../../assets/login.png";
import CommonCard from "../../components/CommonCard";
import { useNavigate } from "react-router-dom";
import { initHistory } from "../../utilities/historyHandler";
import { useDispatch, useSelector } from "react-redux";


const ResetPassword = () => {
  const [loginObj, setLoginObj] = useState({});

  const navigate = useNavigate();
  initHistory(navigate);
  const dispatch = useDispatch()
  const customMessage = useSelector(store=>store.APPLOADER)

  
  const handleContinueToLogin = () => {
    navigate("/login");
  }
  const onCloseHandler = () => {
    dispatch({type:'UPDATE_APP_LOADER',payload:{'showResetPasswordModal':false,modalData:{}}})
  }

  return (
    <div>
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-md-6 p-0">
            <div className="container d-flex justify-content-center align-items-center vh-100">
              <div className="p-4">
                <h2 className="text-center mb-4 fw-700">LOGIN</h2>
                <p>Start & Track your Scheduling Process </p>
                <form className="form-input-custom">
                  <div className="mb-3">
                    {/* <label htmlFor="email" className="form-label">Email</label>
                  <input type="email" className="form-control" id="email" /> */}
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Enter Email Address"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={loginObj?.username}
                        onChange={(e) =>
                          setLoginObj((prev) => {
                            return { ...prev, username: e.target.value };
                          })
                        }
                      />
                    </InputGroup>
                  </div>
                  <div className="text-center d-flex align-items-center flex-column gap-3">
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                    >
                      Reset Password
                    </button>
                    <p>Already User?<span><a className="primary-color" href="/login">Login</a></span></p>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 login_background">
            <div className="row container d-flex justify-content-center align-items-center vh-100">
              <div className="col-md-6">
                <img src={loginimg} alt="" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {customMessage?.showResetPasswordModal && (
        <CommonCard 
          title="Password Reset Link Sent to Email"
          body="Please check your inbox and follow the instructions. to reset your password"
          icon={faEnvelopeCircleCheck}
          color="green"
          actionButton={"Continue to Login"}
          onActionHandler={handleContinueToLogin}
          closeButton={"Close"}
          onCloseHandler={onCloseHandler}
        />
      )}
    </div>
  )
}

export default ResetPassword