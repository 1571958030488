import { call, put, select, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
// import { setHistory } from "../../utilities/historyHandler";
import jobRepository from "../../api/jobRepository";
import { jobsActions } from "../reducers/jobsReducer";
import { setHistory } from "../../utilities/historyHandler";
import { actionsActions } from "../reducers/actionsReducer";
import { appLoaderAppLoader } from "../reducers/appLoader";
import ErrorMsgs from "../../components/ErrorMsgs";

export const recruiterSagaTypes = Object.freeze({
  CREATE_NEW_JOB_DETAILS: "CREATE_NEW_JOB_DETAILS",
  GET_ALL_JOB_DETAILS: "GET_ALL_JOB_DETAILS",
});

function* createJobDetails(action) {

  yield put(actionsActions.updateActions({ overlayLoading: true }));
   const user = yield select((store) => store.RECRUITER);
  try {
    toast.info("Creating new Meeting");
    const response = yield call(() =>
      jobRepository.createJobDetails({...action.payload,vendorId:user.vendorId})
    );
    if (response?.status === "error") {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      yield put(appLoaderAppLoader.updateAppLoader({showJobDescriptionModal:true,modalData:{isSucces:false}}))
      toast.error(
        <ErrorMsgs errorsArray={[response?.message]} message={response?.code} />
      );
    } else {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      yield call(getAllJobDetails);
      setHistory("/dashboard");
      toast.success("Job Details created Successfully");
    }
  } catch (e) {
    toast.error("An error occurred, please try again.");
  }
}

function* getAllJobDetails(action) {
  yield put(appLoaderAppLoader.updateAppLoader({ showEditBookingModal: true }));
  const user = yield select((store) => store.RECRUITER);
  try {
    const response = yield call(() =>
      jobRepository.getAllJobDetails(user.vendorId)
    );
    if (response) {
      yield put(jobsActions.setJobsDetails(response));
    }
  } catch (e) {
    // toast.error("An error occurred, please try again.");
  }
}

function* jobSaga() {
  yield takeEvery(recruiterSagaTypes.CREATE_NEW_JOB_DETAILS, createJobDetails);
  yield takeEvery(recruiterSagaTypes.GET_ALL_JOB_DETAILS, getAllJobDetails);
}

export default jobSaga;
