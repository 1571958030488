import React, {useState} from 'react'
import { Modal } from 'react-bootstrap'
import { initHistory } from "../utilities/historyHandler";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CommonCard = ({ title, body, icon, color, closeButton, onCloseHandler,actionButton,onActionHandler }) => {
    const history = useNavigate();
    initHistory(history);

    const [showModal,setShowModal] = useState(true);
    const textColor = color === 'green' ? 'text-success' : 'text-danger';
    
  return (
    <div>
        <Modal show={showModal} size="md"  centered>
            <Modal.Body>
                <div className="row text-center px-5 py-3">
                    <div className="col-md-12">
                        {icon && <FontAwesomeIcon icon={icon} className={`fs-1 pb-3 ${textColor}`} />}
                        <h4 className={`pb-3 ${textColor}`}>{title}</h4>
                        <p>{body}</p>
                        {actionButton && onActionHandler && (
                            <button
                            className="rp-primary"
                            onClick={onActionHandler}
                            >
                            {actionButton}
                            </button>
                        )}
                        {closeButton && onCloseHandler && (
                            <button className="rp-secondary" onClick={()=>{setShowModal(false);onCloseHandler()}}>
                            {closeButton}
                            </button>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </div>
  )
}

export default CommonCard