import { call, put, select, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
// import { setHistory } from "../../utilities/historyHandler";
// import { jobsActions } from "../reducers/jobsReducer";
import panelistRepository from "../../api/panelistRepository";
import { panelistActions } from "../reducers/panelistReducer";
import { actionsActions } from "../reducers/actionsReducer";
import ErrorMsgs from "../../components/ErrorMsgs";
import { appLoaderAppLoader } from "../reducers/appLoader";

export const panelistSagaTypes = Object.freeze({
  CREATE_NEW_PANELIST: "CREATE_NEW_PANELIST",
  UPDATE_CREATED_PANELIST: "UPDATE_CREATED_PANELIST",
  GET_ALL_PANELIST: "GET_ALL_PANELIST",
  ADD_TEAMMEMBER:"ADD_TEAMMEMBER",
  UPDATE_PANELIST:"UPDATE_PANELIST"
});

function* createPanelist(action) {
  yield put(actionsActions.updateActions({ overlayLoading: true }));
  const user = yield select((store) => store.RECRUITER);
  toast.info("Creating new panelist");
  try {
    const response = yield call(() =>
      panelistRepository.createPanelist({
        ...action.payload,
        vendorId: user.vendorId,
        submitterId: user.userId,
        userId: user.userId,
      })
    );
    yield put(appLoaderAppLoader.updateAppLoader({showPanelistModal:true}))
    if (response?.status === "error") {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      yield put(appLoaderAppLoader.updateAppLoader({showPanelistModal:true,modalData:{isSucces:false}}))
      return toast.error(
        <ErrorMsgs errorsArray={[response?.message]} message={response?.code} />
      );
    } else {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      yield put(appLoaderAppLoader.updateAppLoader({showPanelistModal:true,modalData:{isSucces:true}}))
      yield call(getAllPanelist);
      // toast.success("Panelist created Successfully");
      // setHistory('/panelist')
    }
  } catch (e) {
    toast.error("An error occurred, please try again.");
  }
}


function* updatePanelist(action) {
  yield put(actionsActions.updateActions({ overlayLoading: true }));
  const user = yield select((store) => store.RECRUITER);
  toast.info("Updating panelist");
  try {
    const response = yield call(() =>
      panelistRepository.updatePanelist({
        ...action.payload,
        vendorId: user.vendorId,
        submitterId: user.userId,
        userId: user.userId,
      })
    );
    yield put(appLoaderAppLoader.updateAppLoader({showPanelistModal:true}))
    if (response?.status === "error") {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      yield put(appLoaderAppLoader.updateAppLoader({showPanelistModal:true,modalData:{isSucces:false}}))
      return toast.error(
        <ErrorMsgs errorsArray={[response?.message]} message={response?.code} />
      );
    } else {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
      yield put(appLoaderAppLoader.updateAppLoader({showPanelistModal:true,modalData:{isSucces:true}}))
      yield call(getAllPanelist);
      // toast.success("Panelist created Successfully");
      // setHistory('/panelist')
    }
  } catch (e) {
    toast.error("An error occurred, please try again.");
  }
}

function* addTeamMemeber(action) {
  yield put(actionsActions.updateActions({ overlayLoading: true }));
  const user = yield select((store) => store.RECRUITER);
  toast.info("Adding new Member");
  try {
    const response = yield call(() =>
      panelistRepository.addTeamMember({
        ...action.payload,
        vendorId: user.vendorId,
        submitterId: user.userId,
        userId: user.userId,
      })
    );
    if (response?.status === "error") {
      yield put(actionsActions.updateActions({ overlayLoading: false }));
     return toast.error(
       <ErrorMsgs errorsArray={[response?.message]} message={response?.code} />
     );
      
    } else {
      yield put(actionsActions.updateActions({ overlayLoading: false }));

      // yield call(getAllPanelist);
      // toast.success("Added Memeber Successfully");
      // setHistory("/dashboard");
    }
  } catch (e) {
    toast.error("An error occurred, please try again.");
  }
}

function* getAllPanelist(action) {
  const user = yield select((store) => store.RECRUITER);
  try {
    const response = yield call(() =>
      panelistRepository.getAllPanelists(user.vendorId)
    );
    if (response) {
      yield put(panelistActions.setPanelist(response));
    }
  } catch (e) {
    toast.error("An error occurred, please try again.");
  }
}

function* panelistSaga() {
  yield takeLatest(panelistSagaTypes.CREATE_NEW_PANELIST, createPanelist);
  yield takeLatest(panelistSagaTypes.UPDATE_PANELIST, updatePanelist);
  yield takeLatest(panelistSagaTypes.GET_ALL_PANELIST, getAllPanelist);
  yield takeLatest(panelistSagaTypes.ADD_TEAMMEMBER,addTeamMemeber)
  yield takeLatest(panelistSagaTypes.UPDATE_CREATED_PANELIST,updatePanelist)
}

export default panelistSaga;
